export default {
  path: 'agenda',
  children: [
    {
      path: '',
      name: 'setting.agenda',
      component: () => import('./list/AgendaListPage.vue')
    }
  ]
}
