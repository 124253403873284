import Dashboard from '@/views/dashboard/dashboard/routes'
import HumanResource from '@/views/dashboard/human-resource/routes'
import Production from '@/views/dashboard/production/routes'
import ECommerce from '@/views/dashboard/e-commerce/routes'

export default {
  path: 'dashboard',
  meta: {
    moduleGroup: CONFIG.MODULE_GROUP.DASHBOARD
  },
  component: () => import('@/components/layouts/AppLayout.vue'),
  children: [Dashboard, HumanResource, Production, ECommerce]
}
