export default {
  path: 'scenario',
  children: [
    {
      path: '',
      name: 'manufacturing.scenario',
      component: () => import('./list/ScenarioListPage.vue')
    },
    {
      path: 'add',
      name: 'manufacturing.scenario.add',
      component: () => import('./form/ScenarioFormPage.vue')
    },
    {
      path: 'detail/:uuid',
      name: 'manufacturing.scenario.detail',
      component: () => import('./detail/ScenarioDetailPage.vue')
    }
    // {
    //   path: 'edit/:uuid',
    //   name: 'manufacturing.scenario.edit',
    //   component: () => import('./form/ScenarioFormPage.vue')
    // }
  ]
}
