const API = {
  CMS: {
    POST_LOGIN: '/api/auth/login',
    GET_SESSION: 'api/auth/session',

    // Change Password
    PUT_CHANGE_PASSWORD: '/api/auth/change-password',

    // Media
    GET_MEDIAS: '/api/master/media',

    // Upload Image
    POST_IMAGE: '/api/image',

    // Message Log
    GET_MESSAGE_LOGS: '/api/master/message',

    // Customer
    GET_CUSTOMERS: '/api/customer',
    GET_CUSTOMER: '/api/customer',
    POST_CUSTOMER: '/api/customer',
    PUT_CUSTOMER: '/api/customer',

    // Material Category
    GET_MATERIAL_CATEGORY: 'api/master/material-category',
    GET_MATERIAL_CATEGORIES: 'api/master/material-category',
    POST_MATERIAL_CATEGORY: 'api/master/material-category',
    PUT_MATERIAL_CATEGORY: 'api/master/material-category',
    GET_DROPDOWN_MATERIAL_CATEGORIES: 'api/master/material-category/dropdown',

    // Unit
    GET_UNITS: '/api/master/unit',
    GET_UNIT: '/api/master/unit',
    POST_UNIT: '/api/master/unit',
    PUT_UNIT: '/api/master/unit',
    GET_DROPDOWN_UNIT: '/api/master/unit/dropdown',

    // Factory Type
    GET_DROPDOWN_FACTORY_TYPES: '/api/master/factory-type/dropdown',

    // Product
    GET_PRODUCTS: '/api/costing/product',
    GET_PRODUCT: '/api/costing/product',
    PUT_PRODUCT: '/api/costing/product',
    GET_DROPDOWN_CUSTOMERS: '/api/customer/dropdown',
    GET_PRODUCT_IMAGES: '/api/costing/product/image',
    POST_PRODUCT_IMAGE: '/api/image/product',
    DELETE_PRODUCT_IMAGE: '/api/image/product/image',
    GET_PRODUCT_BILL_OF_MATERIAL: '/api/costing/product/bill-of-material',
    GET_PRODUCT_BILL_OF_PROCESS: '/api/costing/product/bill-of-process',
    GET_PRODUCT_REPORT_NAV: '/api/costing/product/report/nav',
    GET_PRODUCT_REPORT_NAV_V2: '/api/costing/product/report/nav-v2',
    GET_PRODUCT_COST_STRUCTURE: '/api/costing/product/cost-structure',
    GET_PRODUCT_COST_FACTORY: '/api/costing/product/cost-factory',
    GET_PRODUCT_COST_BREAKDOWN: '/api/costing/product/cost-breakdown',

    // Product Part Set
    GET_PRODUCT_PART_SETS: '/api/product-part-set',
    GET_PRODUCT_PART_SET: '/api/product-part-set',
    POST_PRODUCT_PART_SET: '/api/product-part-set',
    PUT_PRODUCT_PART_SET: '/api/product-part-set',
    DELETE_PRODUCT_PART_SET: '/api/product-part-set',

    // Box Carton
    GET_BOX_CARTONS: '/api/product/box-carton',
    GET_BOX_CARTON: '/api/product/box-carton',
    POST_BOX_CARTON: '/api/product/box-carton',
    PUT_BOX_CARTON: '/api/product/box-carton',
    DELETE_BOX_CARTON: '/api/product/box-carton',

    // Box Material Extra
    GET_BOX_MATERIAL_EXTRAS: '/api/product/box-material-extra',
    GET_BOX_MATERIAL_EXTRA: '/api/product/box-material-extra',
    POST_BOX_MATERIAL_EXTRA: '/api/product/box-material-extra',
    PUT_BOX_MATERIAL_EXTRA: '/api/product/box-material-extra',
    POST_BOX_MATERIAL_EXTRA_DUPLICATE:
      '/api/product/box-material-extra/duplicate',
    DELETE_BOX_MATERIAL_EXTRA: '/api/product/box-material-extra',
    GET_DROPDOWN_BOX_MATERIAL_EXTRA_BASE:
      '/api/costing/box-material-extra-base/dropdown',

    // Part
    GET_PARTS: '/api/product/part',
    GET_PART: '/api/product/part', // TODO: Deprecated
    POST_PART: '/api/product/part', // TODO: Deprecated
    PUT_PART: '/api/product/part', // TODO: Deprecated
    DELETE_PART: '/api/product/part',
    POST_CALCULATE_LAMINATION_WIDTH:
      '/api/product/part/calculate/lamination-width',
    POST_SAVE_PART_MATERIAL_AS_PART_TEMPLATE:
      '/api/product/part/save-part-template',

    // Frame
    GET_FRAMES: '/api/product/frame', // TODO: Deprecated
    GET_FRAME: '/api/product/frame', // TODO: Deprecated
    POST_FRAME: '/api/product/frame', // TODO: Deprecated
    PUT_FRAME: '/api/product/frame', // TODO: Deprecated
    DUPLICATE_FRAME: '/api/product/frame/duplicate', // TODO: Deprecated
    DELETE_FRAME: '/api/product/frame', // TODO: Deprecated

    // Part Set
    GET_PART_SETS: '/api/product/part-set',
    GET_PART_SET: '/api/product/part-set',
    POST_PART_SET: '/api/product/part-set',
    PUT_PART_SET: '/api/product/part-set',
    GET_DROPDOWN_PART_SET: '/api/product/part-set/dropdown', // TODO: Deprecated

    // Part Base
    GET_PART_BASES: '/api/product/part-base',
    GET_PART_BASE: '/api/product/part-base',
    POST_PART_BASE: '/api/product/part-base',
    PUT_PART_BASE: '/api/product/part-base',
    GET_DROPDOWN_PART_BASE: '/api/product/part-base/dropdown',

    // Part Template
    GET_PART_TEMPLATES: '/api/product/part-template',
    GET_PART_TEMPLATE: '/api/product/part-template',
    POST_PART_TEMPLATE: '/api/product/part-template',
    PUT_PART_TEMPLATE: '/api/product/part-template',
    DELETE_PART_TEMPLATE: '/api/product/part-template',
    GET_DROPDOWN_PART_TEMPLATE: '/api/product/part-template/dropdown',
    DUPLICATE_PART_TEMPLATE: '/api/product/part-template/duplicate',
    POST_USE_PART_TEMPLATE: '/api/product/part-template/use-template',

    // Part Template Material
    GET_PART_TEMPLATE_MATERIALS: '/api/product/part-template-material',
    GET_PART_TEMPLATE_MATERIAL: '/api/product/part-template-material',
    POST_PART_TEMPLATE_MATERIAL: '/api/product/part-template-material',
    PUT_PART_TEMPLATE_MATERIAL: '/api/product/part-template-material',
    DUPLICATE_PART_TEMPLATE_MATERIAL:
      '/api/product/part-template-material/duplicate',
    DELETE_PART_TEMPLATE_MATERIAL: '/api/product/part-template-material',

    // Formula
    GET_FORMULAS: '/api/master/formula',
    GET_FORMULA: '/api/master/formula',
    PUT_FORMULA_DETAIL: '/api/master/formula-detail',

    // Tag
    POST_TAG: '/api/master/tag',
    GET_DROPDOWN_TAG: '/api/master/tag/dropdown',

    // Operation Setup
    GET_OPERATION_SETUPS: '/api/master/operation-setup',
    GET_OPERATION_SETUP: '/api/master/operation-setup',
    POST_OPERATION_SETUP: '/api/master/operation-setup',
    PUT_OPERATION_SETUP: '/api/master/operation-setup',

    // Labor Cost
    GET_LABOR_COSTS: '/api/master/labor-cost',
    POST_LABOR_COST: '/api/master/labor-cost',

    // Currency Exchange
    GET_CURRENCY_EXCHANGES: '/api/master/currency-exchange',
    POST_CURRENCY_EXCHANGE: '/api/master/currency-exchange',

    // Test
    GET_TESTS: '/api/recruitment/test',
    GET_TEST: '/api/recruitment/test',
    POST_TEST: '/api/recruitment/test',
    PUT_TEST: '/api/recruitment/test',
    GET_DROPDOWN_TESTS: '/api/recruitment/test/dropdown',

    // Question
    GET_QUESTIONS: '/api/recruitment/question',
    GET_QUESTION: '/api/recruitment/question',
    POST_QUESTION: '/api/recruitment/question',
    PUT_QUESTION: '/api/recruitment/question',

    // Applicant Test
    GET_APPLICANT_TESTS: '/api/recruitment/applicant-test',
    GET_APPLICANT_TEST: '/api/recruitment/applicant-test',
    POST_APPLICANT_TEST: '/api/recruitment/applicant-test',
    POST_APPLICANT_TEST_CANCEL: '/api/recruitment/applicant-test/cancel',

    // Applicant Checklist
    GET_APPLICANT_CHECKLISTS: '/api/recruitment/applicant-checklist',
    POST_APPLICANT_CHECKLIST: '/api/recruitment/applicant-checklist',

    // Applicant Interview
    GET_APPLICANT_INTERVIEWS: '/api/recruitment/applicant-interview',
    GET_APPLICANT_INTERVIEW: '/api/recruitment/applicant-interview',
    POST_APPLICANT_INTERVIEW: '/api/recruitment/applicant-interview',
    PUT_APPLICANT_INTERVIEW: '/api/recruitment/applicant-interview',
    POST_APPLICANT_INTERVIEW_CANCEL:
      '/api/recruitment/applicant-interview/cancel',

    // Job Position
    GET_JOB_POSITIONS: '/api/recruitment/job-position',
    GET_JOB_POSITION: '/api/recruitment/job-position',
    POST_JOB_POSITION: '/api/recruitment/job-position',
    PUT_JOB_POSITION: '/api/recruitment/job-position',
    GET_DROPDOWN_JOB_POSITIONS: '/api/recruitment/job-position/dropdown', // TODO: Deprecated

    OPEN: {
      // Invite
      GET_INVITE: '/api/setting/user/invite',
      POST_INVITE: '/api/setting/user/invite/register',

      GET_RESET_PASSWORD: '/api/setting/user/reset-password',
      POST_RESET_PASSWORD: '/api/setting/user/reset-password',
      POST_RESET_PASSWORD_SUBMIT: '/api/setting/user/reset-password/submit'
    },

    COSTING: {
      CALCULATE_COST: {
        POST_PRODUCT: '/api/costing/product/calculate/cost',
        POST_PART: '/api/costing/part/calculate/cost',
        POST_BOX: '/api/costing/box/calculate/cost',
        POST_ALL_BOX_TYPE: '/api/costing/product/calculate/all-box-type'
      },

      // Product
      GET_PRODUCT_SUMMARY: '/api/costing/product/summary',

      // Product Base
      PRODUCT_BASE: {
        GET_LIST: '/api/costing/product-base',
        GET_DETAIL: '/api/costing/product-base',
        POST_CREATE: '/api/costing/product-base',
        PUT_UPDATE: '/api/costing/product-base',
        POST_DUPLICATE: '/api/costing/product-base/duplicate',
        POST_SET_PRIMARY_IMAGE: '/api/costing/product-base/set-primary-image'
      },

      PRODUCT: {
        GET_LIST_BILL_OF_MATERIAL: '/api/costing/product/bill-of-material',
        POST_GENERATE_OPERATION: '/api/costing/product/v2/generate-operation',
        PATCH_DISCOUNT: (uuid: string): string =>
          `/api/costing/product/v2/discount/${uuid}`
      },

      MATERIAL: {
        GET_LIST: '/api/costing/material',
        GET_DROPDOWN: '/api/costing/material/dropdown',
        PUT_UPDATE: (id: number): string => `/api/costing/material/v2/${id}`,
        GET_DETAIL_MATERIAL_JUMBO: (id: number): string =>
          `/api/costing/material/v2/jumbo/${id}`,
        POST_JUMBO: '/api/costing/material/v2/jumbo'
      },

      MATERIAL_CODE: {
        GET_DROPDOWN: '/api/costing/material-code/dropdown'
      },

      MATERIAL_SET: {
        GET_LIST: '/api/costing/material-set',
        GET_DETAIL: '/api/costing/material-set',
        POST_CREATE: '/api/costing/material-set',
        PUT_UPDATE: '/api/costing/material-set',
        GET_DROPDOWN: '/api/costing/material-set/dropdown',
        POST_USE_MATERIAL_SET: '/api/costing/material-set/use-material-set'
      },

      FRAME: {
        GET_LIST: '/api/costing/frame',
        GET_DETAIL: '/api/costing/frame',
        POST_CREATE: '/api/costing/frame',
        PUT_UPDATE: '/api/costing/frame',
        POST_DUPLICATE: '/api/costing/frame/duplicate',
        DELETE: '/api/costing/frame'
      },

      PART_MATERIAL: {
        GET_LIST: '/api/costing/part-material',
        POST_UPDATE_EXWORK: '/api/costing/part-material/v2/update-exwork',
        POST_UPDATE_EXWORK_BY_PARTS:
          '/api/costing/part-material/v2/update-exwork-by-parts'
      },

      // Product Additonal Cost
      GET_PRODUCT_COST_ADDITIONALS: '/api/costing/product-cost-additional',
      GET_PRODUCT_COST_ADDITIONAL: '/api/costing/product-cost-additional',

      // Material
      GET_MATERIALS: '/api/costing/material',
      GET_MATERIAL: '/api/costing/material',
      POST_MATERIAL: '/api/costing/material',
      PUT_MATERIAL: '/api/costing/material',
      GET_MATERIAL_BULK_UPDATE: '/api/costing/material/bulk-update',
      POST_MATERIAL_BULK_UPDATE: '/api/costing/material/bulk-update',

      // Material Category
      MATERIAL_CATEGORY: {
        GET_DROPDOWN: '/api/master/material-category/dropdown'
      },

      // Part Operation
      GET_PART_OPERATIONS: '/api/costing/part-operation',
      GET_PART_OPERATION: '/api/costing/part-operation',
      POST_PART_OPERATION: '/api/costing/part-operation',
      PUT_PART_OPERATION: '/api/costing/part-operation',
      DELETE_PART_OPERATION: '/api/costing/part-operation',

      PART_OPERATION: {
        POST_GENERATE_OPERATION:
          '/api/costing/part-operation/generate-operation'
      },

      // Box Type
      GET_DROPDOWN_BOX_TYPE: '/api/master/box-type/dropdown',

      // Box
      GET_BOXES: '/api/costing/box',
      GET_BOX: '/api/costing/box',
      POST_BOX: '/api/costing/box',
      PUT_BOX: '/api/costing/box',
      DELETE_BOX: '/api/costing/box',
      POST_BOX_DUPLICATE: '/api/costing/box/duplicate',
      POST_BOX_USE_TEMPLATE: '/api/costing/box/reset-box-material',

      // Box Material
      GET_BOX_MATERIALS: '/api/costing/box-material',
      GET_BOX_MATERIAL: '/api/costing/box-material',
      POST_BOX_MATERIAL: '/api/costing/box-material',
      PUT_BOX_MATERIAL: '/api/costing/box-material',
      DELETE_BOX_MATERIAL: '/api/costing/box-material',

      // Box Accessory
      GET_BOX_ACCESSORIES: '/api/costing/box-accessory',
      GET_BOX_ACCESSORY: '/api/costing/box-accessory',
      POST_BOX_ACCESSORY: '/api/costing/box-accessory',
      PUT_BOX_ACCESSORY: '/api/costing/box-accessory',
      DUPLICATE_BOX_ACCESSORY: '/api/costing/box-accessory/duplicate',
      DELETE_BOX_ACCESSORY: '/api/costing/box-accessory',

      // Box Operation
      GET_BOX_OPERATIONS: '/api/costing/box-operation',
      POST_BOX_OPERATION_USE_TEMPLATE:
        '/api/costing/box-operation/use-template',

      // Operation
      GET_OPERATIONS: '/api/costing/operation',
      GET_OPERATION: '/api/costing/operation',
      POST_OPERATION: '/api/costing/operation',
      PUT_OPERATION: '/api/costing/operation',
      GET_DROPDOWN_OPERATIONS: '/api/costing/operation/dropdown',

      // Part Base
      GET_DROPDOWN_PART_BASE: '/api/product/part-base/dropdown',

      // Part Set
      GET_DROPDOWN_PART_SET: '/api/product/part-set/dropdown',

      // Part Group
      GET_PART_GROUPS: '/api/costing/part-group',
      GET_PART_GROUP: '/api/costing/part-group',
      POST_PART_GROUP: '/api/costing/part-group',
      PUT_PART_GROUP: '/api/costing/part-group',
      GET_DROPDOWN_PART_GROUP: '/api/costing/part-group/dropdown',

      // Product Part Group
      GET_PRODUCT_PART_GROUPS: '/api/costing/product-part-group',
      GET_PRODUCT_PART_GROUP: '/api/costing/product-part-group',
      POST_PRODUCT_PART_GROUP: '/api/costing/product-part-group',
      PUT_PRODUCT_PART_GROUP: '/api/costing/product-part-group',
      DELETE_PRODUCT_PART_GROUP: '/api/costing/product-part-group',
      GET_DROPDOWN_PRODUCT_PART_GROUP:
        '/api/costing/product-part-group/dropdown',

      // Product
      POST_PRODUCT_PRICING: '/api/costing/product/update-exwork',
      POST_PRODUCT_IMAGE: '/api/costing/product/image',

      // Part
      GET_PART: '/api/product/part',
      POST_PART: '/api/product/part',
      PUT_PART: '/api/product/part',
      POST_PART_DUPLICATE: '/api/costing/part/duplicate',

      POST_PART_CALCULATE_LAMINATION_WIDTH:
        '/api/costing/part/calculate/lamination-width',

      // Part Material
      GET_PART_MATERIALS: '/api/costing/part-material',
      GET_PART_MATERIAL: '/api/costing/part-material',
      POST_PART_MATERIAL: '/api/costing/part-material',
      PUT_PART_MATERIAL: '/api/costing/part-material',
      POST_PART_MATERIAL_DUPLICATE: '/api/costing/part-material/duplicate',
      DELETE_PART_MATERIAL: '/api/costing/part-material',

      // Formula
      GET_DROPDOWN_FORMULAS: '/api/costing/formula/dropdown'
    },

    SETTING: {
      // Role
      GET_ROLES: '/api/setting/role',
      GET_ROLE: '/api/setting/role',
      POST_ROLE: '/api/setting/role',
      PUT_ROLE: '/api/setting/role',
      GET_DROPDOWN_ROLES: '/api/setting/role/dropdown',

      // User
      GET_USERS: '/api/setting/user',
      GET_USER: '/api/setting/user',
      POST_USER: '/api/setting/user',
      PUT_USER: '/api/setting/user',
      GET_DROPDOWN_USERS: '/api/setting/user/dropdown',

      // User Invite
      POST_USER_INVITE: '/api/setting/user/invite',
      POST_USER_INVITE_RESEND: '/api/setting/user/invite/resend',

      // Reset Password
      POST_USER_RESET_PASSWORD: '/api/setting/user/reset-password',

      // Region
      GET_DROPDOWN_REGION: '/api/setting/region/dropdown',

      CRON_MANAGEMENT: {
        GET_LIST: '/api/cron-management/tasks',
        POST_EXECUTE: (taskId: string) =>
          `/api/cron-management/tasks/${taskId}/execute`
      },

      // Agenda
      AGENDA: {
        // Jobs
        GET_JOBS: '/api/agenda/jobs',
        GET_JOB: (id: string) => `/api/agenda/jobs/${id}`,
        POST_RETRY_JOB: (id: string) => `/api/agenda/jobs/${id}/retry`,
        DELETE_JOB: (id: string) => `/api/agenda/jobs/${id}`,

        // Stats and Definitions
        GET_STATS: '/api/agenda/stats',
        GET_DEFINITIONS: '/api/agenda/definitions'
      }
    },

    EMPLOYEE: {
      // Employee
      GET_DROPDOWN_EMPLOYEES: '/api/employee/employee/dropdown',

      // Branch
      GET_DROPDOWN_BRANCH: '/api/employee/branch/dropdown',

      // Overtime
      GET_OVERTIMES: '/api/employee/overtime',
      GET_OVERTIME: '/api/employee/overtime',
      POST_OVERTIME: '/api/employee/overtime',
      PUT_OVERTIME: '/api/employee/overtime',
      PUT_OVERTIME_VALIDATE: '/api/employee/overtime/validate',
      POST_OVERTIME_SYNC_ALL: '/api/employee/overtime/sync-all',
      POST_OVERTIME_FINISH: '/api/employee/overtime/finish',
      POST_OVERTIME_SUBMIT_EMPLOYEE: '/api/employee/overtime/submit-attandance',
      POST_OVERTIME_CHECK_ATTENDANCE: '/api/employee/overtime/check-attendance',
      POST_OVERTIME_CHECK_STATUS: '/api/employee/overtime/check-status',

      QUESTION: {
        GET_LIST: '/api/employee/question',
        GET_DETAIL: (uuid: string) => `/api/employee/question/${uuid}`,
        POST_CREATE: '/api/employee/question',
        PUT_UPDATE: (uuid: string) => `/api/employee/question/${uuid}`
      },

      QUESTIONNAIRE_TEMPLATE: {
        GET_LIST: '/api/employee/questionnaire-template',
        GET_DETAIL: (uuid: string) =>
          `/api/employee/questionnaire-template/${uuid}`,
        POST_CREATE: '/api/employee/questionnaire-template',
        PUT_UPDATE: (uuid: string) =>
          `/api/employee/questionnaire-template/${uuid}`
      }
    },

    RECRUITMENT: {
      DASHBOARD: {
        GET_APPLICANT_TEST: '/api/recruitment/dashboard/applicant-test'
      },

      // Applicant
      GET_APPLICANTS: '/api/recruitment/applicant',
      GET_APPLICANT: '/api/recruitment/applicant',
      POST_APPLICANT: '/api/recruitment/applicant',
      PUT_APPLICANT: '/api/recruitment/applicant',
      GET_APPLICANT_KANBAN: '/api/recruitment/applicant/kanban',
      GET_APPLICANT_EXPORT_TALENTA: '/api/recruitment/applicant/export/talenta',
      GET_DROPDOWN_APPLICANT_STATUS:
        '/api/recruitment/applicant/status/dropdown',
      GET_DROPDOWN_HIRE_STATUS:
        '/api/recruitment/applicant/hire-status/dropdown',
      PUT_APPLICANT_STATUS: '/api/recruitment/applicant/status',
      PUT_APPLICANT_ARCHIVE: '/api/recruitment/applicant/archive',

      // Job Position
      GET_DROPDOWN_JOB_POSITIONS: '/api/recruitment/job-position/dropdown'
    },

    RETAIL: {
      // Product
      PRODUCT: {
        GET_LIST: '/api/retail/v2/product',
        POST_ADD_FLASH_SALE: '/api/retail/v2/product/add-flash-sale',
        POST_REMOVE_FLASH_SALE: '/api/retail/v2/product/remove-flash-sale',
        GET_LIST_FLASH_SALE: '/api/retail/v2/product/flash-sale',
        POST_UPDATE_SORT_FLASH_SALE: '/api/retail/v2/product/sort-flash-sale'
      },

      LEGACY: {
        GET_DETAIL_PRODUCT: `/api/retail/legacy/product`
      },

      // Product Template
      PRODUCT_TEMPLATE: {
        GET_DETAIL: (uuid: string) => `/api/retail/product-template/${uuid}`,
        POST_CREATE: '/api/retail/product-template',
        PUT_UPDATE: (uuid: string) => `/api/retail/product-template/${uuid}`,
        POST_BULK_UPDATE_PRODUCT:
          '/api/retail/product-template/bulk-update-product',
        POST_SYNC_ALGOLIA: '/api/retail/product-template/sync-algolia'
      },

      // Banner
      BANNER: {
        GET_LIST: '/api/retail/banner',
        GET_DETAIL: (uuid: string) => `/api/retail/banner/${uuid}`,
        POST_CREATE: '/api/retail/banner',
        PUT_UPDATE: (uuid: string) => `/api/retail/banner/${uuid}`
      },

      // Document
      DOCUMENT: {
        GET_LIST: '/api/retail/document',
        GET_DETAIL: (uuid: string) => `/api/retail/document/${uuid}`,
        PUT_UPDATE: (uuid: string) => `/api/retail/document/${uuid}`
      },

      // Payment Method
      PAYMENT_METHOD: {
        GET_LIST: '/api/retail/payment-method',
        GET_DETAIL: (uuid: string) => `/api/retail/payment-method/${uuid}`,
        POST_CREATE: '/api/retail/payment-method',
        PUT_UPDATE: (uuid: string) => `/api/retail/payment-method/${uuid}`
      },

      // Room
      ROOM: {
        GET_LIST: '/api/retail/room',
        GET_DETAIL: (uuid: string) => `/api/retail/room/${uuid}`,
        POST_CREATE: '/api/retail/room',
        PUT_UPDATE: (uuid: string) => `/api/retail/room/${uuid}`,
        GET_DROPDOWN: '/api/retail/room/dropdown'
      },

      // Order
      ORDER: {
        GET_LIST: '/api/retail/order',
        GET_DETAIL: (uuid: string) => `/api/retail/order/${uuid}`,
        POST_CONFIRM: '/api/retail/order/confirm',
        POST_SHIP: '/api/retail/order/ship',
        POST_DELIVER: '/api/retail/order/deliver'
        // POST_CANCEL: '/api/retail/order/cancel',
        // POST_CONFIRM: '/api/retail/order/confirm',
        // POST_DELIVER: '/api/retail/order/deliver',
        // POST_PAYMENT: '/api/retail/order/payment',
        // POST_REFUND: '/api/retail/order/refund',
        // POST_REJECT: '/api/retail/order/reject',
        // POST_RETURN: '/api/retail/order/return',
        // POST_SHIP: '/api/retail/order/ship',
        // POST_SYNC_ALGOLIA: '/api/retail/order/sync-algolia'
      },

      // Product Category
      PRODUCT_CATEGORY: {
        POST_ADD_FEATURED: '/api/retail/product-category/add-featured',
        POST_REMOVE_FEATURED: '/api/retail/product-category/remove-featured',
        GET_LIST_FEATURED: '/api/retail/product-category/featured',
        POST_UPDATE_SORT_FEATURED: '/api/retail/product-category/sort-featured'
      },

      // GET_PRODUCT_TEMPLATES: '/api/retail/product-template',
      // GET_PRODUCT_TEMPLATE: '/api/retail/product-template',
      POST_PRODUCT_TEMPLATE: '/api/retail/product-template',
      PUT_PRODUCT_TEMPLATE: '/api/retail/product-template',

      // Product Category
      GET_PRODUCT_CATEGORIES: '/api/retail/product-category',
      GET_PRODUCT_CATEGORY: '/api/retail/product-category',
      POST_PRODUCT_CATEGORY: '/api/retail/product-category',
      PUT_PRODUCT_CATEGORY: '/api/retail/product-category',
      GET_DROPDOWN_PRODUCT_CATEGORY: '/api/retail/product-category/dropdown',
      POST_PRODUCT_CATEGORY_GENERATE_DISPLAY_NAME:
        '/api/retail/product-category/generate-display-name',

      // Modifier
      GET_MODIFIERS: '/api/retail/modifier',
      GET_MODIFIER: '/api/retail/modifier',
      POST_MODIFIER: '/api/retail/modifier',
      PUT_MODIFIER: '/api/retail/modifier',
      GET_DROPDOWN_MODIFIER: '/api/retail/modifier/dropdown',

      // Store
      GET_STORES: '/api/retail/store',
      GET_STORE: '/api/retail/store',
      POST_STORE: '/api/retail/store',
      PUT_STORE: '/api/retail/store'
    },

    // Manufacturing
    MANUFACTURING: {
      PRODUCTION_ORDER: {
        GET_LIST: '/api/manufacturing/production-order',
        GET_DETAIL: (uuid: string) =>
          `/api/manufacturing/production-order/${uuid}`,
        POST_CREATE: '/api/manufacturing/production-order',
        PUT_UPDATE: (uuid: string) =>
          `/api/manufacturing/production-order/${uuid}`,
        GET_GANTT_CHART: (id: number) =>
          `/api/manufacturing/schedule/production-orders/${id}/work-orders`
      },

      SCENARIO: {
        GET_LIST: '/api/manufacturing/scenario',
        GET_DETAIL: (uuid: string) => `/api/manufacturing/scenario/${uuid}`,
        POST_CREATE: '/api/manufacturing/scenario',
        PUT_UPDATE: (uuid: string) => `/api/manufacturing/scenario/${uuid}`,
        POST_GENERATE_SCHEDULE: '/api/manufacturing/scenario/generate-schedule'
      },

      PRODUCT: {
        GET_LIST: '/api/manufacturing/product',
        GET_DETAIL: (uuid: string) => `/api/manufacturing/product/${uuid}`,
        POST_IMPORT_COSTING_PRODUCT:
          '/api/manufacturing/product/import/costing-product'
      }
    },

    APPROVAL: {
      GET_APPROVAL_TYPES: '/api/approval/approval-type',
      GET_APPROVAL_TYPE: '/api/approval/approval-type',
      POST_APPROVAL_TYPE: '/api/approval/approval-type',
      PUT_APPROVAL_TYPE: '/api/approval/approval-type',
      GET_DROPDOWN_APPROVAL_TYPE: '/api/approval/approval-type/dropdown',

      POST_APPROVAL_REQUEST: '/api/approval/approval/request',

      GET_APPROVAL_BY_MODULE: '/api/approval/approval',
      GET_MY_APPROVALS: '/api/approval/approval-approver/my-approvals',
      GET_APPROVAL_APPROVER: '/api/approval/approval-approver',
      PUT_APPROVAL_APPROVER: '/api/approval/approval-approver'
    },

    REPORT: {
      EMPLOYEE: {
        GET_DAILY_ATTENDANCE: '/api/report/employee/attendance/daily',
        GET_WEEKLY_ATTENDANCE: '/api/report/employee/attendance/weekly'
      }
    }
  },
  PUBLIC: {
    // Registration
    GET_APPLICANT: '/api/public/applicant',
    PUT_APPLICANT: '/api/public/applicant',
    POST_APPLICANT_DOCUMENT_UPLOAD: '/api/public/applicant-document',

    // Test
    POST_EXCHANGE_TEST_CODE: '/api/public/test/exchange-test-code',
    GET_TEST_SESSION: '/api/public/test',
    GET_TEST_QUESTION: '/api/public/test/question',
    POST_TEST_ANSWER: '/api/public/test/submit-answer',

    // Interview
    POST_EXCHANGE_INTERVIEW_CODE:
      '/api/public/interview/exchange-interview-code',
    GET_INTERVIEW: '/api/public/interview',
    PUT_INTERVIEW: '/api/public/interview'
  }
}

export default API
