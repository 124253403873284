import Dashboard from '@/views/retail/dashboard/routes'
import ProductTemplate from '@/views/retail/product-template/routes'
import ProductCategory from '@/views/retail/product-category/routes'
import Modifier from '@/views/retail/modifier/routes'
import Banner from '@/views/retail/banner/routes'
import Store from '@/views/retail/store/routes'
import Document from '@/views/retail/document/routes'
import PaymentMethod from '@/views/retail/payment-method/routes'
import Room from '@/views/retail/room/routes'
import Order from '@/views/retail/order/routes'

export default {
  path: 'retail',
  meta: {
    moduleGroup: CONFIG.MODULE_GROUP.RETAIL
  },
  component: () => import('@/components/layouts/AppLayout.vue'),
  children: [
    Dashboard,
    ProductTemplate,
    ProductCategory,
    Modifier,
    Banner,
    Store,
    Document,
    PaymentMethod,
    Room,
    Order
  ]
}
