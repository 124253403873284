export default {
  path: 'production-order',
  children: [
    {
      path: '',
      name: 'manufacturing.production-order',
      component: () => import('./list/ProductionOrderListPage.vue')
    },
    {
      path: 'add',
      name: 'manufacturing.production-order.add',
      component: () => import('./form/ProductionOrderFormPage.vue')
    },
    {
      path: 'detail/:id',
      name: 'manufacturing.production-order.detail',
      component: () => import('./detail/ProductionOrderDetailPage.vue')
    }
  ]
}
