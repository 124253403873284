export default {
  path: 'human-resource',
  children: [
    {
      path: '',
      name: 'dashboard.human-resource',
      component: () => import('./HumanResourcePage.vue')
    }
  ]
}
