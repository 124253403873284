export default {
  path: 'e-commerce',
  children: [
    {
      path: '',
      name: 'dashboard.e-commerce',
      component: () => import('./ECommercePage.vue')
    }
  ]
}
