export default {
  path: 'question',
  children: [
    {
      path: '',
      name: 'employee.question',
      component: () => import('./list/QuestionListPage.vue')
    },
    {
      path: 'add',
      name: 'employee.question.add',
      component: () => import('./form/QuestionFormPage.vue')
    },
    {
      path: ':uuid',
      name: 'employee.question.edit',
      component: () => import('./form/QuestionFormPage.vue')
    }
  ]
}
