export default {
  path: 'payment-method',
  children: [
    {
      path: '',
      name: 'retail.payment-method',
      component: () => import('./list/PaymentMethodListPage.vue')
    },
    {
      path: 'add',
      name: 'retail.payment-method.add',
      component: () => import('./form/PaymentMethodFormPage.vue')
    },
    {
      path: 'edit/:uuid',
      name: 'retail.payment-method.edit',
      component: () => import('./form/PaymentMethodFormPage.vue')
    }
  ]
}
