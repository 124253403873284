export default {
  path: 'questionnaire-template',
  children: [
    {
      path: '',
      name: 'employee.questionnaire-template',
      component: () => import('./list/QuestionnaireTemplateListPage.vue')
    },
    {
      path: 'add',
      name: 'employee.questionnaire-template.add',
      component: () => import('./form/QuestionnaireTemplateFormPage.vue')
    }
    // {
    //   path: ':uuid',
    //   name: 'employee.question.edit',
    //   component: () => import('./form/QuestionFormPage.vue')
    // }
  ]
}
