import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from '@/views/auth/login/LoginPage.vue'

// Setting
import Setting from '@/views/setting/routes'

// Dashboard
import Dashboard from '@/views/dashboard/routes'

// Costing
import Costing from '@/views/costing/routes'

// Employee
import Employee from '@/views/employee/routes'

// Recruitment
import Recruitment from '@/views/recruitment/routes'
import RecruitmentOpen from '@/views/recruitment-open/routes'

// Retail
import Retail from '@/views/retail/routes'

// Manufacturing
import Manufacturing from '@/views/manufacturing/routes'

// Approval
import Approval from '@/views/approval/routes'

// Public
import Open from '@/views/open/routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/app',
      component: () => import('@/components/layouts/MainLayout.vue'),
      children: [
        {
          path: '', // Empty path to render the home component inside MainLayout
          name: 'home',
          component: () => import('@/views/home/HomePage.vue')
        },
        Setting,
        Dashboard,
        Costing,
        Employee,
        Recruitment,
        Retail,
        Manufacturing,
        Approval
      ]
    },
    {
      path: '/open',
      children: [RecruitmentOpen]
    },
    Open,
    {
      path: '/auth/login',
      name: 'login',
      component: LoginPage
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'home' }
    }
  ]
})

export default router
