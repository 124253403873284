export default {
  path: 'production',
  children: [
    {
      path: '',
      name: 'dashboard.production',
      component: () => import('./ProductionPage.vue')
    }
  ]
}
