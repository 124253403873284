export default {
  path: 'cron-management',
  children: [
    {
      path: '',
      name: 'setting.cron-management',
      component: () => import('./list/CronManagementListPage.vue')
    }
  ]
}
