export default {
  path: 'order',
  children: [
    {
      path: '',
      name: 'retail.order',
      component: () => import('./list/OrderListPage.vue')
    },
    {
      path: 'detail/:uuid',
      name: 'retail.order.detail',
      component: () => import('./detail/OrderDetailPage.vue')
    }
  ]
}
