export default {
  path: 'dashboard',
  children: [
    {
      path: '',
      name: 'manufacturing.dashboard',
      component: () => import('./main/DasboardPage.vue')
    }
  ]
}
