import Dashboard from '@/views/manufacturing/dashboard/routes'
import ProductionOrder from '@/views/manufacturing/production-order/routes'
import Scenario from '@/views/manufacturing/scenario/routes'

export default {
  path: 'manufacturing',
  meta: {
    moduleGroup: CONFIG.MODULE_GROUP.MANUFACTURING
  },
  component: () => import('@/components/layouts/AppLayout.vue'),
  children: [Dashboard, ProductionOrder, Scenario]
}
