export default {
  path: 'room',
  children: [
    {
      path: '',
      name: 'retail.room',
      component: () => import('./list/RoomListPage.vue')
    },
    {
      path: 'add',
      name: 'retail.room.add',
      component: () => import('./form/RoomFormPage.vue')
    },
    {
      path: 'edit/:uuid',
      name: 'retail.room.edit',
      component: () => import('./form/RoomFormPage.vue')
    }
  ]
}
